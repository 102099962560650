import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
//import AboutProject from '../../../../../components/case-studies/AboutProject'
import Footer from '../../../../../components/case-studies/Footer'
import PagesShowcase from '../../../../../components/case-studies/PagesShowcase'
import Layout from '../../../../../components/layout'
import { graphql } from 'gatsby'

class Dpb extends Component {
  render() {
    const { data } = this.props

    return (
      <Layout showBackButton={true}>
        <Landing
          title={'DPB'}
          description={'Prípadová štúdia'}
          text={'Pre Dopravný podnik Bratislava a.s., sme navrhli a vytvorili graifcké podklady a tlačové materiály pre kampaň.'}
          img={data.landingImage}
          what_we_done={[
            { text: 'návrh letákov a informačných materiálov', link: '/nase-prace/grafika/digital-print/dpb' },
          ]}
          noShadow={true}
        />

        {/*<AboutProject
          text={'Spolupráca s DPB prebiehala nasledovne:'}
          list={[
            {
              title: 'Ako to prebiehalo',
              text: 'Poďme sa spolu pozrieť na jednotlivé etapy vývoja projektu. Každý jeden projekt a klient je individuálny, podľa toho sa líši aj rozsah a detail prác na projekte. O momentálnej fáze tvorby je klient vždy informovaný a poskytuje nám svoj feedback.',
            },
            {
              title: 'Zadanie',
              text: 'Klient nám poskytol zadanie, ktoré sme si spolu odkonzultovali a spracovali do projektovej dokumentácie, ktorou sa následne riadil celý projekt. Niektorí klienti majú vlastnú špecifikáciu, iným ju spracujeme my na základe požiadaviek. Pri rozsiahlejších projektoch sa navrhuje celá architektúra, rozpisujú sa detailne všetky funkcie a kreslia sa use-case modely.',
            },
            {
              title: 'Riešenie',
              text: 'Od začiatku prác na projekte sa vyčlení menší tím, ktorý sa venuje práve danému projektu. Snažíme sa pracovať non-stop, aby už po krátkej dobe videl klient nejakú ukážku alebo prototyp a videli sme, či ideme správnou cestou. Ďalej projekt konzultujeme a posielame updaty, až kým sa nedopracujeme k výsledku, s ktorým sme aj my aj klient spokojný.',
            },
            {
              title: 'Výsledok',
              text: 'Na konci developmentu posielame klientovi beta verziu, kde si vie skontrolovať celý design a všetky funkcionality. Na tejto verzii rovnako testujeme aj my a optimalizujeme posledné detaily pred spustením projektu live.',
            }
          ]}
        />*/}

        <PagesShowcase
          title={'Na detailoch záleží'}
          description={'Precíznosť v každej maličkosti'}
          text={'Dokonalosť je ukrytá v detailoch. Aj na prvý pohľad jednoduché prvky môžu skrývať často viac ako sa zdá. Práve docieliť jednoduchosť a estetickú príťažlivosť je výzvou pre každé grafické studio.'}
          projectDetail={'/nase-prace/grafika/digital-print/dpb'}
          itemsTitle={'Design koncepty'}
          className={'white-background pt-0'}
          pages={[
            {
              name: 'Lístky',
              image: data.pageOne,
            },
            {
              name: 'Leták',
              image: data.pageTwo,
            },
            {
              name: 'Skladačka',
              image: data.pageThree,
            },
          ]} />

        {/*<ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        />*/}


        <div className="extern-page-link">
          <a rel="noreferrer" href="https://www.dpb.sk" target='_blank' className='link-secondary'>www.dpb.sk</a>
        </div>

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Fertilomat'}
          nextProjectUrl={'/nase-prace/pripadove-studie/grafika/digital-print/fertilomat'}
        />
      </Layout>
    )
  }
}

export const query = graphql`{
    landingImage: file(relativePath: { eq: "case-studies/DPB_landing.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
        childImageSharp {
            fixed( width: 250) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    pageOne: file(relativePath: { eq: "case-studies/1_dpb2.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageTwo: file(relativePath: { eq: "case-studies/2_dpb2.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageThree: file(relativePath: { eq: "case-studies/3_dpb2.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`

export default Dpb
